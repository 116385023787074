import * as React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import { graphql, useStaticQuery, Link } from "gatsby"
import Sale from "../components/Sale/Sale"
import Hero from "../components/Hero/Hero"

import * as styles from "../scss/Index.module.scss"
import "../scss/index.scss"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale {
        edges {
          node {
            id
            name
            slug
            description {
              description
            }
            startDate
            endDate
            mainMedia {
              gatsbyImageData
              title
            }
            lots {
              lotId
              auctionId
              estimate
              additionalMedia {
                file {
                  contentType
                  url
                }
                gatsbyImageData(height: 400)
              }
              asset {
                ... on ContentfulNftAsset {
                  id
                  yearOfWork
                  title
                  slug
                  artist {
                    name
                    slug
                  }
                  mainMedia {
                    gatsbyImageData
                    title
                    file {
                      contentType
                      url
                    }
                  }
                }
                ... on ContentfulPhysicalAsset {
                  id
                  yearOfWork
                  title
                  slug
                  artist {
                    name
                    slug
                  }
                  mainMedia {
                    gatsbyImageData
                    title
                    file {
                      contentType
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Artnet Auctions" />
      <Hero data={data.allContentfulSale.edges[0].node} />

      {data.allContentfulSale.edges.map((edge: any) => (
        <Sale key={edge.node.id} data={edge.node} />
      ))}

      <div className={styles.indexViewAll}>
        <Link to={`/all-artworks`}>View All Artworks</Link>
      </div>
    </>
  )
}

export default IndexPage
