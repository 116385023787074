import React, { useMemo } from "react"

import loadable from "@loadable/component"
import { Link } from "gatsby"
import MasonryGrid from "../MasonryGrid/MasonryGrid"

import * as styles from "./Sale.module.scss"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

const LoadableGalleryMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const Sale = ({ data }) => {
  const organizationId = useMemo(() => {
    return parseInt(process.env.GATSBY_MONEGRAPH_ORGANIZATION_ID as string)
  }, [])

  const auctions = useMemo(() => {
    return getAuctionsIds(data?.lots)
  }, [data])

  return (
    <div className={styles.sale}>
      <Link to={`/sale/${data.slug}`}>
        <h2>{data.name}</h2>
      </Link>

      <LoadableGalleryMonitor
        organizationId={organizationId}
        auctions={auctions}
        render={({ result }) => {
          const enrichedData = enricheLotWithAuction(
            data?.lots?.slice(0, 4) || [],
            result?.auctions
          )

          return <MasonryGrid data={enrichedData} />
        }}
      />
    </div>
  )
}

export default Sale
