import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import { slugify } from "../../utils/string"
import { isAfter, isSameMonth } from "date-fns"
import { utcToZonedTime, format } from "date-fns-tz"

import * as styles from "./Hero.module.scss"
import { isBefore } from "date-fns/esm"

const Hero = ({ data }) => {
  const date = new Date()
  const dateTimezoned = utcToZonedTime(date, "EST")

  const startDate = new Date(data.startDate)
  const startDateTimezoned = utcToZonedTime(startDate, "EST")

  const endDate = new Date(data.endDate)
  const endDateTimezoned = utcToZonedTime(endDate, "EST")

  const auctionHasStarted = isBefore(dateTimezoned, startDateTimezoned)

  const { displayDate }: any = useMemo(() => {
    if (auctionHasStarted) {
      return {
        displayDate: `Lots begin closing at ${format(
          endDateTimezoned,
          "HH:mm aaa (zzz) MMMM dd"
        )}`,
      }
    } else {
      if (isSameMonth(startDateTimezoned, endDateTimezoned)) {
        return {
          displayDate: `Works are available for purchase on ${format(
            startDateTimezoned,
            "MMMM dd"
          )} through ${format(endDateTimezoned, "dd")}`,
        }
      } else {
        return {
          displayDate: `Works are available for purchase on ${format(
            startDateTimezoned,
            "MMMM dd"
          )} through ${format(endDateTimezoned, "MMMM dd")}`,
        }
      }
    }
  }, [auctionHasStarted, startDateTimezoned, endDateTimezoned])

  return (
    <div className={styles.banner}>
      <GatsbyImage
        image={getImage(data.mainMedia)}
        alt={data.mainMedia.title}
        className={styles.bannerImg}
      />
      <h3>{data.name}</h3>
      <p>{displayDate}</p>
      <Link to={`/sale/${data.slug}`}>Bid Now</Link>
    </div>
  )
}

export default Hero
